.top-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.image-size {
  width: 20px;
  height: 20px;
}

.mid-controls {
  position: absolute;
  top: 50%;
  top: -webkit-calc(50% - 25px);
  top: -moz-calc(50% - 25px);
  top: calc(50% - 25px);
  left: -webkit-calc(50% - 25px);
  left: -moz-calc(50% - 25px);
  left: calc(50% - 25px);
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: black;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls-container {
  display: flex;
  flex-direction: column;
  height: inherit;
  position: relative;
}
.mid-controls,
.play,
.volume {
  pointer-events: auto;
}

.controls-inactive {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0.5s, opacity 0.5s;
  -moz-transition: visibility 0.5s, opacity 0.5s;
  -ms-transition: visibility 0.5s, opacity 0.5s;
  -o-transition: visibility 0.5s, opacity 0.5s;
  transition: visibility 0.5s, opacity 0.5s;
}
