.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* variables */
:root {
  --primary: #ffc636;
  --secondary: #0c0a6f;
}
/* reset */
body,
p,
a,
ul,
li {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
li {
  list-style-type: none;
}
/* base styles */
body {
  /* background: var(--secondary); */
  overflow-x: hidden;
}
.button {
  background: none;
  border: 2px solid var(--primary);
  color: var(--primary);
  padding: 6px 12px;
  border-radius: 10px;
  text-transform: uppercase;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.6);
  display: inline-block;
  font-size: 1em;
  margin-left: 5px;
}
.button:hover {
  color: #222;
  background: var(--primary);
}
input {
  background: rgba(255, 255, 255, 0.05);
  padding: 10px 16px;
  border-radius: 10px;
  border: 2px solid #9893d8;
  color: #959090;
  font-size: 1em;
}
.error {
  color: rgb(187, 30, 30);
  text-align: left;
  margin-left: 2px;
}
/* fonts */
body {
  color: #959090;
}
/* mobile styles */
.App {
  margin: 5%;
}
.form {
  text-align: center;
}
.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  box-sizing: border-box;
}
.todos {
  margin-top: 20px;
}
.todos li {
  margin-top: 10px;
  background-color: #6767ab;
  padding: 3%;
  box-shadow: 1px 2px 3px honeydew;
  cursor: pointer;
}
.content {
  grid-column: 1/8;
  color: white;
}
.todo-action {
  display: flex;
  font-size: 1.5em;
}
.headingColor {
  color: #625151;
}
/* small tablet styles */
@media screen and (min-width: 620px) {
  input {
    width: 300px;
  }
  .todos li {
    margin: 10px 20%;
    padding: 2%;
  }
}
/* large tablet & laptop styles */
@media screen and (min-width: 960px) {
  .todos li {
    margin: 10px 25%;
  }
}
@media screen and (min-width: 1200px) {
  .todos li {
    margin: 10px 33%;
    padding: 1%;
  }
}

.video-js {
  border-radius: 10px;
}
