.item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  background-color: #ffffff;
  color: grey;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
}
.item:hover {
  background-color: #0092ff;
  filter: brightness(110%);
  color: #ffffff;
}

.thumbnail {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  position: relative;
}

.divider {
  margin: 0px;
}
.selected {
  background-color: #0092ff;
  color: white;
}
.duration {
  position: absolute;
  width: 50px;
  height: 25px;
  background-color: black;
  text-align: center;
  top: 0px;
  bottom: 0px;
  left: 20px;
  color: white;
  opacity: 0.7;
}

.body {
  padding-top: 5px;
}
