.main-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
}

.profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.subscribe {
  width: 90px;
  height: 35px;
}

.subscribe-button {
  height: inherit;
  width: inherit;
  background-color: rgb(0, 146, 255);
  color: white;
  border: none;
  border-radius: 20px;
  font-weight: 600;
  font-size:12px;
}

.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12;
  font-weight: 400;
  color:#ffffff;
}

.image-outer{
  border-radius: 50%;
  padding-top:5px;
}

.text{
  font-size: 12;
  font-weight: 500;
  color:#ffffff;
}