.side-menu-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.image-container {
  width: 50px;
  height: 50px;
  background-color: rgb(0, 146, 255);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image {
  width: 25px;
  height: 25px;
}

.action-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
