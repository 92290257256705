.playlist-container {
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  min-width: 275px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.header {
  padding: 10px;
  font-size: 20px;
  font-weight: 500;
}

.list {
  background-color: rgb(243, 247, 252);
  padding: 5px 5px 5px 5px;
  overflow-y: auto;
  height: 100%;
}
