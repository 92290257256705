body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.Mui-checked {
  color: #0092ff !important;
}

.MuiCheckbox-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.video-js .vjs-control-bar {
  background-color: #0092ffb3 !important;
}

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  background-color: #0092ffb3 !important;
}
